import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import staticallyLogo from '../../static/images/branding/statically-logo-dark.svg';

const topLinks = [
  { name: 'BlobCDN', href: 'https://blobcdn.com' },
  { name: 'Tiktok Downloader', href: 'https://toktiktok.com' },
  { name: 'Favicone', href: 'https://favicone.com' },
  { name: 'PureDNS', href: 'https://puredns.org' },
  { name: 'thedev.id', href: 'https://thedev.id' },
  { name: 'Indiwtf', href: 'https://indiwtf.com' },
];

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <>
      <nav className="relative isolate items-center gap-x-6 overflow-hidden bg-gray-50 py-2 hidden lg:block">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div className="flex text-gray-600 justify-between px-5 mx-auto">
          <div className="text-sm leading-6">
            <p>Tools/projects you may like:</p>
          </div>
          <div className="flex lg:gap-x-6">
            {topLinks.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm leading-6 hover:text-black transition"
                data-umami-event={'top-link-' + item.name}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      </nav>
      <nav className="border-b flex items-center justify-between flex-wrap px-5 py-5 lg:py-0">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <h2>
            <Link
              to="/"
              id="logo"
              className="flex items-center no-underline mr-6"
            >
              <span className="font-bold text-xl tracking-tight hidden">
                {siteTitle}
              </span>
              <img
                className="h-8"
                src={staticallyLogo}
                alt="Statically"
                title="Statically logo"
              />
            </Link>
          </h2>
        </div>

        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-black border-black hover:text-red-500 hover:border-red-500"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>

        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
        >
          <div className="text-lg lg:flex-grow pt-3 lg:pt-0">
            <Link
              to="/docs/"
              className="block py-2 lg:py-6 lg:inline-block lg:mt-0 mr-4 hover:text-red-600 border-b-2 border-transparent"
              activeClassName="text-red-600 lg:border-red-600"
            >
              Docs
            </Link>
            <Link
              to="/convert/"
              className="block py-2 lg:py-6 lg:inline-block lg:mt-0 mr-4 hover:text-red-600 border-b-2 border-transparent"
              activeClassName="text-red-600 lg:border-red-600"
            >
              Convert
            </Link>
            <Link
              to="/support-statically/"
              className="block py-2 lg:py-6 lg:inline-block lg:mt-0 mr-4 hover:text-red-600 border-b-2 border-transparent"
              activeClassName="text-red-600 lg:border-red-600"
            >
              Sponsors
            </Link>
          </div>

          <div className="flex mt-3 lg:mt-0">
            <a
              href="https://www.patreon.com/fransallen"
              className="inline-block font-semibold py-1 px-2 rounded overflow-hidden relative bg-black border-2 border-black text-white btn-custom"
              rel="noopener noreferrer"
              target="_blank"
            >
              Donate via Patreon
            </a>
          </div>

          <div className="lg:hidden lg:gap-x-6 py-8">
            <h3 className="font-bold mb-4">More tools</h3>
            <div className="space-y-4">
              {topLinks.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block leading-6 hover:text-black transition"
                  data-umami-event={'top-link-mobile-' + item.name}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
